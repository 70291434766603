import {useCallback, useEffect, useState} from 'react'
import {
  // BlobRef,
  // BskyAgent,
  ComAtprotoServerTelegramBind,
  // ComAtprotoServerTelegramParticipants,
} from '@atproto/api'
import {useQuery} from '@tanstack/react-query'
import type {TelegramClient} from 'telegram'

// import {uploadBlobImage} from '#/lib/api'
import {isNative} from '#/platform/detection'
import {useAgent, useSession} from '#/state/session'
import {
  getChannelChats,
  // getChannelChats,
  // getChannelFullData,
  // getChannelParticipants,
  // getChatFullData,
  getContacts,
  getTelegramDialogs,
} from '#/view/com/modals/TelegramModal/util'
import {ContactItemProps} from '#/view/screens/Circle'
import * as telegram from '../../../../../web/telegram'
import {useTgStore} from '../../util/sdlStore/TgStore'
import {
  apiHash,
  apiId,
  NEED_INVITE_TELE_USER_COUNT,
  TELEGRAM_SESSION_KEY,
} from './config'
// import Queue from './queue'
// import SimpleQueue from './simpleQueue'

let clientRef: TelegramClient | null = null

export function useTelegramClient() {
  const [client, setClient] = useState<TelegramClient | null>(clientRef)
  useEffect(() => {
    if (isNative) return
    const init = async () => {
      const {TelegramClient, sessions} = telegram
      const session = new sessions.StringSession(
        localStorage.getItem(TELEGRAM_SESSION_KEY) || '',
      )
      const _client = new TelegramClient(session, apiId, apiHash, {
        connectionRetries: 5,
      })
      clientRef = _client
      setClient(_client)
    }

    if (!clientRef) {
      init()
    }
  }, [])

  const resetClient = (token?: string) => {
    if (isNative) return
    const {TelegramClient, sessions} = telegram
    if (token) {
      localStorage.setItem(TELEGRAM_SESSION_KEY, token)
    }
    const session = new sessions.StringSession(
      localStorage.getItem(TELEGRAM_SESSION_KEY) || '',
    )
    const _client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    })
    clientRef = _client
    setClient(_client)
    return _client
  }

  return {client, resetClient}
}

export const telegramUserBaseInfo = ['TelegramUserBaseInfo']
// type T = [bigInt.BigInteger, Blob]
export const useTelegramUserBaseInfo = () => {
  const {currentAccount} = useSession()
  const agent = useAgent()
  return useQuery({
    enabled: !!currentAccount?.accessJwt,
    queryKey: telegramUserBaseInfo,
    queryFn: () => {
      return agent.com.atproto.server.getTelegramUserBasicInfo()
    },
  })
}

export const useTelegramLink = () => {
  const [binding, setBinding] = useState(false)
  const [bind, setBind] = useState(false)
  const {setTgStoreState} = useTgStore()
  const [syncContactStep, setSyncContactStep] = useState<
    'syncing' | 'synced' | 'saved'
  >('syncing')
  const [shouldInviteCount, setShouldInviteCount] = useState<number>(
    NEED_INVITE_TELE_USER_COUNT,
  )
  const {client} = useTelegramClient()
  const agent = useAgent()

  const {data, error, status} = useTelegramUserBaseInfo()

  useEffect(() => {
    if (status === 'pending') {
      setBinding(true)
    }

    if (error) {
      setBinding(false)
      setBind(false)
    }

    if (data && status === 'success') {
      const {telegramId, invited} = data.data || {}
      setShouldInviteCount(count => count - (invited?.length || 0))
      if (!telegramId || telegramId === '0') {
        setBind(false)
      } else {
        setBind(true)
      }
      setBinding(false)
    }
  }, [data, error, status])

  const handleBind = useCallback(async () => {
    // logger.log('handleBind', {client, bind})
    if (client) {
      try {
        setBinding(true)
        const me = await client.getMe()
        const myId = parseInt(me.id.toString(), 10)

        const bindParams: ComAtprotoServerTelegramBind.InputSchema = {
          id: myId,
          username: me.username || '',
          lastname: me.lastName || '',
          firstname: me.firstName || '',
          phone_number: me.phone || '',
        }
        setTgStoreState({
          userName: me.username || me.firstName || '' + me.lastName || '',
          telegramId: myId + '',
          hasConnected: true,
        })
        return agent.com.atproto.server
          .telegramBind(bindParams)
          .then(({data}: any) => {
            if (data.ok) {
              setBind(true)
              // location.reload()
            } else {
              setBind(false)
            }
          })
      } catch (err) {
        setBind(false)
        console.error(err)
      } finally {
        setBinding(false)
      }
    } else {
      setBind(false)
    }
  }, [agent.com.atproto.server, client, setTgStoreState])

  const saveChannels = useCallback(async () => {
    if (client) {
      getChannelChats(client, agent)
    }
  }, [agent, client])

  const saveChannelParticipants = useCallback(async () => {
    console.log(client)
    if (client) {
      getTelegramDialogs(client, agent)
    }
  }, [agent, client])

  const saveContacts = useCallback(async (): Promise<
    ContactItemProps[] | undefined
  > => {
    if (client) {
      setSyncContactStep('syncing')
      await client.connect()
      const me = await client.getMe()
      const res = await getContacts(client)

      setSyncContactStep('synced')

      console.log('getContacts:', res)

      if (res) {
        // const _uploadBlobImage = (agent: BskyAgent) => (img: string | Blob) =>
        //   uploadBlobImage(agent, img)
        const contacts = res.contacts
          .filter(contact => contact.userId !== me.id)
          .map((contact: any) => {
            return {
              user_id: +contact.userId.toString(),
              mutual: contact.mutual,
            }
          })

        // const queue = res.users
        //   .filter(user => user.id !== me.id)
        //   .map(user =>
        //     Promise.all([user.id, client.downloadProfilePhoto(user)]),
        //   )

        // const imageUploadQueue: T[] = []
        // const userAvatarMap = new Map<string, string>()

        // for await (const [id, buf] of queue) {
        //   if (buf && buf.length > 0) {
        //     const img = new Blob([buf], {type: 'image/jpeg'})
        //     userAvatarMap.set(id.toString(), URL.createObjectURL(img))
        //     imageUploadQueue.push([id, img])
        //     // imageUploadQueue.push(
        //     //   Promise.all([id, uploadBlobImage(agent, img)]),
        //     // )
        //   }
        // }

        // const map = new Map<bigInt.BigInteger, BlobRef>()

        // const taskQueue = new Queue({
        //   queue: imageUploadQueue,
        //   task: _uploadBlobImage(agent),
        // })

        // const r = await taskQueue.run()

        // console.log('queue:', r)

        // for (let i = 0; i < r.length; i++) {
        //   try {
        //     map.set(r[i][0], r[i][1])
        //   } catch (err) {
        //     console.log(err)
        //   }
        // }

        const users = res.users.map((user: any) => {
          return {
            id: +user.id.toString(),
            /** The telegram firstname. */
            username: user.username || '',
            /** The telegram lastname. */
            firstname: user.firstName || '',
            /** The telegram lastname. */
            lastname: user.lastName || '',
            /** The telegram user number. */
            phone_number: user.phone || '',
            // photo: {}, // map.get(user.id) || undefined,
          }
        })

        const params = {
          contacts,
          users,
          save_count: res.savedCount,
        }

        console.log('params:', params)

        agent.com.atproto.server
          .telegramContacts({
            contacts: params,
            tele_user_id: +me.id.toString(),
          })
          .then(() => {
            setSyncContactStep('saved')
          })

        return users.map(u => {
          return {
            id: u.id.toString(),
            username: u.username,
            nickname: `${u.firstname} ${u.lastname}`,
            avatar: '', //userAvatarMap.get(u.id.toString()),
            from: 'telegram',
            isMutual:
              contacts.find(item => item.user_id === +u.id.toString())
                ?.mutual ?? false,
          }
        })
      }
    }
  }, [agent, client])

  return {
    bind,
    binding,
    syncContactStep,
    handleBind,
    shouldInviteCount,
    saveContacts,
    saveChannels,
    saveChannelParticipants,
  }
}
