import {
  ComAtprotoRepoUploadBlob,
  ComAtprotoServerDefs,
  ComAtprotoServerGetCircleUsers,
} from '@atproto/api'
import {User} from '@atproto/api/dist/client/types/com/atproto/server/circleUsersBatch'
import {Circle_ref} from '@atproto/api/dist/client/types/com/atproto/server/getCircleList'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {uploadBlobImage} from '#/lib/api/upload-blob'
import {useAgent} from '#/state/session'
import {Tag} from '#/view/com/tags/TagItem'
import {formatHandler} from '#/screens/Onboarding/util'
import {
  CircleFromType,
  CircleItemParams,
  CircleItemProps,
  CircleMemberPrams,
  CircleMembersParams,
  ContactItemProps,
} from '.'

const RQKEY_ROOT = 'circle'
export const RQKEY = (did: string) => [RQKEY_ROOT, did]

export const Circle = {
  List: 'circle.list',
  Edit: 'circle.edit',
  Create: 'circle.create',
  Detail: 'circle.detail',
  Members: 'circle.members',
  Tags: 'circle.friend.tags',
  Request: ['cirlce.request.list'],
}

export const telegramContactList = ['TelegramContactList']

export const useCircleList = () => {
  const agent = useAgent()
  const res = useQuery({
    queryKey: [Circle.List],
    queryFn: () => {
      return agent.com.atproto.server.getCircleList().then(res => {
        return res.data.result?.map((item: Circle_ref) => {
          return {
            id: item.id,
            name: item.circle_name,
            description: item.description,
            avatar: item.photo,
            banner: item.banner,
            members: item.member_avatars,
            private: !item.isPublic,
            createBy: item.createBy,
            memberCount: item.member_count,
            isOwner: item.can_manage,
          } as CircleItemProps
        })
      })
    },
  })
  return {
    data: res.data
      ?.sort((a, b) => Number(b.id) - Number(a.id))
      ?.sort((a, b) => (b.isOwner ? 1 : 0) - (a.isOwner ? 1 : 0)),
    isLoading: res.isLoading,
    isError: res.isError,
    error: res.error,
  }
}

export const useCircleRequestList = (circleId?: string) => {
  const agent = useAgent()
  return useQuery({
    queryKey: [Circle.Request, circleId],
    queryFn: async () => {
      const res = await agent.com.atproto.server.circleQueryApproveInfos({
        circle_id: circleId ?? '',
        limit: 500,
        offset: 0,
      })
      return res.data.approveInfos || []
    },
  })
}

export const useCircleItem = (id: number) => {
  const agent = useAgent()
  const res = useQuery({
    queryKey: [Circle.Detail, `${id}`],
    queryFn: () => {
      return agent.com.atproto.server
        .getCircleInfo({circle_id: id})
        .then(res => {
          return {
            id: res.data.circle_id,
            name: res.data.circle_name,
            bio: res.data.description,
            avatar: res.data.photo,
            banner: res.data.banner,
            private: !res.data.isPublic,
            createBy: res.data.createBy,
            isOwner: res.data.can_manage,
            creator: res.data.creator,
            memberCount: res.data.member_count,
          } as CircleItemProps
        })
    },
  })
  return {
    data: res.data,
    isLoading: res.isLoading,
    isError: res.isError,
    error: res.error,
  }
}

export const useCircleDeleteMutatation = () => {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async ({circleId}: {circleId: number}) => {
      await agent.com.atproto.server.circleDelete({
        circle_id: +circleId,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [Circle.List],
      })
    },
  })
}

export const useCircleDeleteMemberMutation = () => {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async ({
      circleId,
      members = [],
      sourceType,
    }: {
      circleId: number
      members: string[]
      sourceType: CircleFromType
    }) => {
      await agent.com.atproto.server.circleUserDeleteBatch({
        circle_id: +circleId,
        source_type: sourceType,
        users: members,
      })
    },
    onSuccess(data, variables) {
      queryClient.invalidateQueries({
        queryKey: [Circle.Members, {id: variables.circleId}],
      })
      queryClient.invalidateQueries({
        queryKey: [Circle.List, `${variables.circleId}`],
      })
    },
  })
}

export const useCircleMembers = (id: number) => {
  const agent = useAgent()
  return useQuery({
    queryKey: [Circle.Members, {id, type: 'all'}],
    queryFn: () => {
      return agent.com.atproto.server
        .getCircleUsers({circle_id: id, source_type: 'all'})
        .then(res => {
          return res.data.users.map(user => {
            return {
              id: user.source_user_id,
              username: user.user_name,
              avatar: user.photo,
              from: user.source_type,
            } as ContactItemProps
          })
        })
    },
  })
}

export const useCircleAccount = (id: number) => {
  const agent = useAgent()
  return useQuery({
    queryKey: [Circle.Members, {id}],
    queryFn: () => {
      return agent.com.atproto.server
        .getCircleUsers({circle_id: id, source_type: 'all'})
        .then((res: ComAtprotoServerGetCircleUsers.Response) => {
          return res.data.users
        })
    },
  })
}

export function removeDuplicatesWithSet<T>(arr: T[], key: keyof T): T[] {
  const seen = new Set<any>()
  return arr.filter(item => {
    if (seen.has(item[key])) {
      return false
    }
    seen.add(item[key])
    return true
  })
}

export const useTelegramContactList = ({
  circleId,
  query,
  limit = 1000,
  offset = 0,
  enabled = true,
  tags,
}: {
  circleId: number
  query?: string
  limit?: number
  offset?: number
  enabled?: boolean
  tags?: Tag[]
}) => {
  const agent = useAgent()
  return useQuery({
    enabled,
    queryKey: [telegramContactList, {circleId, query, limit, offset, tags}],
    queryFn: () => {
      return agent.com.atproto.server
        .getTelegramContactsPage({
          circleId,
          limit,
          offset,
          q: query,
          tagIds: tags?.map(t => t.id).join(','),
        })
        .then(res => {
          return (
            res.data?.contacts?.map(
              (item: ComAtprotoServerDefs.TgContactInfo) => {
                return {
                  id: item.userId,
                  nickname: item.firstname + item.lastname,
                  username: item.username,
                  avatar: item.photo,
                  tags: item.tags?.map(t => {
                    return {
                      id: t.tagId,
                      name: t.tag,
                    }
                  }),
                  from: 'telegram',
                  degree: item.degree,
                  isAdded: item?.isBindSipz,
                  isInvite: item?.isInvite,
                  // lastInviteDate: item?.lastInviteDate,
                } as ContactItemProps
              },
            ) || []
          )
        })
    },
  })
}

type ContactsInSpizParams = {
  q?: string
  limit?: number
  offset?: number
  enabled?: boolean
}
export const telegramContactsWithSpiz = ['elegramContactsWithSpiz']

export const useTelegramContactsWithSpiz = ({
  q,
  limit = 1000,
  offset = 0,
  enabled = true,
}: ContactsInSpizParams) => {
  const agent = useAgent()
  return useQuery({
    enabled,
    queryKey: [telegramContactsWithSpiz, {q, limit, offset}],
    queryFn: async () => {
      const params = {q, limit, offset}
      const res = await Promise.allSettled([
        agent.com.atproto.server.getTelegramContactsWithSpizPage({
          ...params,
          isBind: true,
        }),
        agent.com.atproto.server.getTelegramContactsWithSpizPage({
          ...params,
          isBind: false,
        }),
      ])

      const list = res
        .filter(item => item.status === 'fulfilled')
        .map(item => item.value.data.contacts || [])
        .flat(3)

      return (
        list.map((item: ComAtprotoServerDefs.TgContactInfo) => {
          return {
            id: item.userId,
            nickname: item.firstname + item.lastname,
            username: item.username,
            avatar: item.photo,
            from: 'telegram',
            isMutual: item.isMutual,
          } as ContactItemProps
        }) || []
      )
    },
  })
}

export const telegramBindWithSpiz = ['telegramBindWithSpiz']
export const useTelegramBindWithSpiz = (userIds: string[]) => {
  const agent = useAgent()
  return useQuery({
    enabled: userIds.length > 0,
    queryKey: [telegramBindWithSpiz],
    queryFn: () => {
      return agent.com.atproto.server.queryTgBindResult({userIds}).then(res => {
        return res.data.bindResults
          .filter(item => item.isBind)
          .map(item => item.userId)
      })
    },
  })
}

export function useCircleUpdateMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<void, Error, CircleItemParams>({
    mutationFn: async ({
      circle,
      newCircleAvatar,
      // newUserBanner,
    }) => {
      let newUserAvatarPromise:
        | Promise<ComAtprotoRepoUploadBlob.Response>
        | undefined
      if (newCircleAvatar) {
        newUserAvatarPromise = uploadBlobImage(
          agent,
          newCircleAvatar.path,
          newCircleAvatar.mime,
        )
      }
      // let newUserBannerPromise:
      //   | Promise<ComAtprotoRepoUploadBlob.Response>
      //   | undefined
      // if (newUserBanner) {
      //   newUserBannerPromise = uploadBlob(
      //     agent,
      //     newUserBanner.path,
      //     newUserBanner.mime,
      //   )
      // }
      const res = await newUserAvatarPromise

      await agent.com.atproto.server.circleInfoUpdate({
        circle_id: +circle.id,
        circle_name: circle.name,
        description: circle.bio,
        is_public: !circle.private,
        photo: res?.data.blob,
        // banner: newUserBannerPromise?.then(res => res.data.blob),
      })
    },
    onSuccess(_data, variables) {
      // invalidate cache
      queryClient.invalidateQueries({
        queryKey: [Circle.List],
      })
      queryClient.invalidateQueries({
        queryKey: [Circle.Detail, variables.circle.id],
      })
    },
  })
}
// export function useCircleCreateMutation({
//     callback,
// }: {callback: (data: any)=>void}) {
//   const queryClient = useQueryClient()
//   const agent = useAgent()
//   return useMutation<Response, Error, CircleItemParams>({
//     mutationFn: async ({
//       circle,
//       newCircleAvatar,
//       // newUserBanner,
//     }) => {
//       let newUserAvatarPromise:
//         | Promise<ComAtprotoRepoUploadBlob.Response>
//         | undefined
//       if (newCircleAvatar) {
//         newUserAvatarPromise = uploadBlobImage(
//           agent,
//           newCircleAvatar.path,
//           newCircleAvatar.mime,
//         )
//       }
//       // let newUserBannerPromise:
//       //   | Promise<ComAtprotoRepoUploadBlob.Response>
//       //   | undefined
//       // if (newUserBanner) {
//       //   newUserBannerPromise = uploadBlob(
//       //     agent,
//       //     newUserBanner.path,
//       //     newUserBanner.mime,
//       //   )
//       // }
//       const res = await newUserAvatarPromise
//       return await agent.com.atproto.server.circleInfoAdd({
//         circle_name: circle.name,
//         description: circle.bio,
//         is_public: !circle.private,
//         photo: res?.data?.blob,
//       })
//     },
//     onSuccess(_data, variables) {
//         callback?.(_data)
//       // invalidate cache
//       queryClient.invalidateQueries({
//         queryKey: [Circle.List],
//       })
//       queryClient.invalidateQueries({
//         queryKey: [Circle.Detail, variables.circle.id],
//       })
//     },
//   })
// }
export function useCircleMembersUpdateMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<void, Error, CircleMembersParams>({
    mutationFn: async ({circleId, members = []}) => {
      await agent.com.atproto.server.circleUsersBatch({
        circle_id: +circleId,
        users: members.map((member: CircleMemberPrams) => {
          return {
            source_user_id: member.id,
            source_type: member.from,
          } as User
        }),
      })
    },
    onSuccess(_data, variables) {
      // invalidate cache
      queryClient.invalidateQueries({
        queryKey: [Circle.List],
      })
      queryClient.invalidateQueries({
        queryKey: [Circle.Detail, variables.circleId],
      })
      queryClient.invalidateQueries({
        queryKey: [Circle.Members, {id: variables.circleId}],
      })
    },
  })
}

export function useTagList() {
  const agent = useAgent()
  return useQuery({
    queryKey: [Circle.Tags],
    queryFn: async () => {
      return agent.com.atproto.server.getTags({}).then(res => {
        return (
          (res.data.tags || []).map(t => {
            return {
              id: t.id,
              name: t.tag,
            } as Tag
          }) || []
        )
      })
    },
  })
}

export function useTagUpdateMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<void, Error, Tag>({
    mutationFn: async ({id, name}) => {
      if (id === -1) {
        await agent.com.atproto.server.tagAdd({
          tag: name,
        })
      } else {
        await agent.com.atproto.server.tagUpdate({
          tag: name,
        })
      }
    },
    onSuccess() {
      // invalidate cache
      queryClient.invalidateQueries({
        queryKey: [Circle.Tags],
      })
    },
  })
}

export type UserTagsParams = {
  userId: string
  tags: Tag[]
  fromType: CircleFromType
}

export function useUpdateUserTagsMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<void, Error, UserTagsParams>({
    mutationFn: async ({userId, tags, fromType}) => {
      await agent.com.atproto.server.tagUserAddTags({
        other_id: userId,
        other_type: fromType,
        tag_ids: tags.map(t => {
          return +t.id
        }),
      })
    },
    onSuccess(_data, _variables) {
      // invalidate cache
      const _variablesKey =
        _variables?.fromType === 'telegram'
          ? telegramContactList
          : spizContactList
      queryClient.invalidateQueries({
        queryKey: [_variablesKey, {}],
      })
    },
  })
}

export const spizContactList = ['SpizContactList']
export const useSipzContactList = ({
  circleId,
  query,
  limit = 1000,
  offset = 0,
  enabled = true,
  tags,
}: {
  circleId: number
  query?: string
  limit?: number
  offset?: number
  enabled?: boolean
  tags?: Tag[]
}) => {
  const agent = useAgent()
  return useQuery({
    enabled,
    queryKey: [spizContactList, {circleId, query, limit, offset, tags}],
    queryFn: () => {
      return agent.com.atproto.server
        .getSpizFriends({
          circleId,
          limit,
          offset,
          q: query,
          tagIds: tags?.map(t => t.id).join(','),
        })
        .then(res => {
          return (
            res.data?.friends
              ?.sort(
                (a, b) => (b.status === 1 ? 1 : 0) - (a.status === 1 ? 1 : 0),
              )
              ?.map(item => {
                return {
                  id: item.did,
                  nickname: item.displayName,
                  username: formatHandler(item.handle),
                  avatar: item.avatar,
                  tags: item.tags?.map(t => {
                    return {id: t.tagId, name: t.tag}
                  }),
                  from: 'sipz',
                  degree: 1,
                  isAdded: item?.status !== 0,
                } as ContactItemProps
              }) || []
          )
        })
    },
  })
}
