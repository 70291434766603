import React, {useMemo} from 'react'
import {Image, StyleSheet, Text, View} from 'react-native'
import {PullStatus, PullToRefreshify} from 'react-pull-to-refreshify'

import {batchedUpdates} from '#/lib/batchedUpdates'
import {useNonReactiveCallback} from '#/lib/hooks/useNonReactiveCallback'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {FeedPostSliceItem} from '#/state/queries/post-feed'
import {CenteredView} from '../util/Views'
import {FeedItem} from './Feed'
import {MemoCard} from './MemoCard'

interface ICircleFeedList {
  data: FeedItem[]
  onEndReached: (data: {distanceFromEnd: number}) => void
  refreshing: boolean
  onRefresh: () => void
}

export default function CircleFeedList({
  data,
  onEndReached,
  refreshing,
  onRefresh,
}: ICircleFeedList) {
  const arrayData = useMemo((): FeedPostSliceItem[] => {
    const array = data.map(ele => {
      if (ele.type === 'slice') {
        return ele.slice.items
      } else {
        return []
      }
    })
    return array.flat()
  }, [data])
  const {isWideList} = useWebMediaQueries()

  function renderText(status: PullStatus) {
    switch (status) {
      case 'pulling':
        return <Text style={styles.text}>Pull to refresh</Text>

      case 'canRelease':
        return <Text style={styles.text}>Release to refresh</Text>

      case 'refreshing':
        return (
          <View
            style={{
              height: 100,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 15,
            }}>
            <Image
              source={{
                uri: '/sipzloading.gif',
              }}
              style={{width: 60, height: 60, borderRadius: 8}}
              accessibilityIgnoresInvertColors
            />
          </View>
        )

      case 'complete':
        return 'Refresh succeed'

      default:
        return ''
    }
  }

  return (
    <CenteredView>
      <PullToRefreshify
        refreshing={refreshing}
        headHeight={80}
        startDistance={1}
        onRefresh={() => onRefresh()}
        renderText={renderText}>
        <View style={[styles.container]}>
          {arrayData.map((item, index) => (
            <MemoCard
              index={isWideList ? index : undefined}
              key={item.uri}
              post={item.post}
              record={item.record}
              style={{width: isWideList ? '50%' : '100%'}}
            />
          ))}
          <View style={{width: 23, height: 90}} />
          <Visibility
            onVisibleChange={isVisible => {
              isVisible && onEndReached({distanceFromEnd: 0})
            }}
          />
        </View>
      </PullToRefreshify>
    </CenteredView>
  )
}

let Visibility = ({
  onVisibleChange,
}: {
  onVisibleChange: (isVisible: boolean) => void
}): React.ReactNode => {
  const tailRef = React.useRef(null)
  const isIntersecting = React.useRef(false)

  const handleIntersection = useNonReactiveCallback(
    (entries: IntersectionObserverEntry[]) => {
      batchedUpdates(() => {
        entries.forEach(entry => {
          if (entry.isIntersecting !== isIntersecting.current) {
            isIntersecting.current = entry.isIntersecting
            onVisibleChange(entry.isIntersecting)
          }
        })
      })
    },
  )

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `0px`,
    })
    const tail: Element | null = tailRef.current!
    observer.observe(tail)
    return () => {
      observer.unobserve(tail)
    }
  }, [handleIntersection])

  return <View ref={tailRef} style={styles.visibilityDetector} />
}
Visibility = React.memo(Visibility)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  listContainer: {
    justifyContent: 'flex-start',
    paddingTop: 30,
    marginBottom: 100,
  },
  item: {
    flex: 1,
    margin: 5,
    height: 100,
    backgroundColor: '#4CAF50',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
  },
  visibilityDetector: {
    height: 80,
    width: 50,
    pointerEvents: 'none',
    zIndex: -1,
    position: 'absolute',
    bottom: 200,
    left: 39,
  },
})
